import React from 'react';

import { LayoutLoggedIn } from '../../components/views/layout';
import { Users }  from '../../components/views/users/users';

export default function() {
    return (
        <LayoutLoggedIn>
            <Users />
        </LayoutLoggedIn>
    );
}