import React, { memo } from 'react'
import { WarningModal } from '../../reusableModals/warningModal'
import { Input } from '../../common/form/fields'

import * as styles from './users.module.scss'
import { GeneratePasswordModalProps } from './types'

const GeneratePasswordModal = ({
   userGeneratedPassword,
   isPasswordModalOpen,
   handleClosePasswordModal,
   modalDetails,
   copyButtonText,
   passwordRef,
   handleCopyText,
}: GeneratePasswordModalProps) => (
   <WarningModal
      isModalOpen={isPasswordModalOpen}
      handleClose={handleClosePasswordModal}
      title="User Password"
      hasChildren={true}
      primaryButtonMethod={handleCopyText}
      primaryButtonTitle={copyButtonText}
      secondaryButtonMethod={handleClosePasswordModal}
      secondaryButtonTitle="Close"
      secondaryButtonVariant="tertiary"
      fixedSize={false}
   >
      <>
         <div>
            <label className={styles.formLabels}>Name:&nbsp;</label>
            <span>&nbsp; {modalDetails.Name ?? '-'}</span>
         </div>
         <div>
            <label className={styles.formLabels}>Company:&nbsp;</label>
            <span>&nbsp; {modalDetails.Company ?? '-'}</span>
         </div>
         <div>
            <label className={styles.formLabels}>Email:&nbsp;</label>
            <span>&nbsp; {modalDetails.Email ?? '-'}</span>
         </div>
         <label className={styles.generatePasswordLabel}>Password:&nbsp;</label>
         <Input
            id="password"
            label="New Password"
            value={userGeneratedPassword}
            ref={passwordRef}
            isReadonly
         />
      </>
   </WarningModal>
)

export default memo(GeneratePasswordModal)
